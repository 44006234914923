<template>
 <div :class="windowClass">
   <v-container>
    <v-row>
      <v-col cols="12">
                <v-card>
               <v-toolbar>
                 <v-icon left color="error">mdi-refresh</v-icon> Reset All Data
                 <v-spacer/>
                 
                  <v-divider vertical inset class="ml-5 mr-2"/>
                  <v-btn text small color="warning"  @click="form=true, state='events'">Reset Events</v-btn>
                  <v-divider vertical inset class="ml-5 mr-2"/>
                  <v-btn text small color="warning"  @click="form=true,  state='fights'">Reset Fights</v-btn>
                  <v-divider vertical inset class="ml-5 mr-2"/>
                  <v-btn text small color="warning"  @click="form=true,  state='bets'">Reset Bets</v-btn>
               </v-toolbar>
              
             </v-card>
          </v-col>
    </v-row>
    </v-container>
    <va-void-form :show="form" :state="state" @DialogEvent="afEvent" />
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        areaform: false,
        state: 'new',
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
        
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      afEvent() {
        this.form = false
     
      }
     
    }
  }
</script>